<template>
	<v-spacer />
</template>

<script>
/**
 * @displayName w-spacer
 *
 * @since 0.3.2
 */
export default {
	name: 'WSpacer'
}
</script>
